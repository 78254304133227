// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faClock          as farFaClock          } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faComment        as farFaComment        } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faFolder         as farFaFolder         } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { faQuestionCircle as farFaQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faTag            as farFaTag            } from "@fortawesome/pro-regular-svg-icons/faTag";
import { faUserCircle     as farFaUserCircle     } from "@fortawesome/pro-regular-svg-icons/faUserCircle";

import { faBars           as fasFaBars           } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faChevronDown    as fasFaChevronDown    } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faChevronLeft    as fasFaChevronLeft    } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { faChevronRight   as fasFaChevronRight   } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import { faEnvelope       as fasFaEnvelope       } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faSearch         as fasFaSearch         } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faMobileAlt      as fasFaMobileAlt      } from "@fortawesome/pro-solid-svg-icons/faMobileAlt";

import { faFacebookF      as fabFaFacebookF      } from "@fortawesome/free-brands-svg-icons/faFacebookF";

/**
 * Add regular icons
 */
library.add(farFaClock, farFaComment, farFaFolder, farFaQuestionCircle, farFaTag, farFaUserCircle);

/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaChevronDown, fasFaChevronLeft, fasFaChevronRight, fasFaEnvelope, fasFaSearch, fasFaMobileAlt);

/**
 * Add free brands icons
 */
library.add(fabFaFacebookF);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
